import { styled, ThemeProvider } from '@mui/material';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { CollapsibleText, InfoText } from '..';
import ProcedureContext from '../../context/ProcedureContext';
import { SkipProcedureDialog } from './Dialog';
import { isAnySandboxMode, ProcedureStatus } from '../../enums';
import { InstructionContent } from '../../types';
import { VisitReferenceType } from '../../types/QuestionInterface';

const StyledInfoText = styled(InfoText)`
  .InstructionsHeader {
    justify-content: space-between;
    padding-bottom: 22px
  }
  h3 {
    color: ${(props) => props.theme.palette.h.main};
    margin: 0;
    font-size: 1.45em;
    font-weight: 500;
    font-size: 16px;
  }

  ul.InstructionBodyItems {
      list-style: none;
      padding: 0;
      font-weight: 500;
      font-size: 14px;
      text-indent: -23px;
  }

  ul.InstructionBodyItems li {
      padding-left: 23px; // info circle icon width + right margin
  }

  .InfoCircleIcon {
      margin-right: 5px;
      margin-bottom: 3px;
      vertical-align: middle;
      color: #009ceb;
      height: 18px;
  }

  p + p {
    margin-top: 1em;
  }

  p.ReferencedFrom ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-inline-start: 20px;
  }

  p.MuiTypography-root {
    font-size: 1em;
  }
`;

const SkipProcedureButton = styled(InfoText)`
  padding: 5px 20px;
  background-color: #eceade;

  &.Disabled {
    pointer-events: none;

    p.MuiTypography-root {
      opacity: .6;
    }
  }

  &:hover {
    cursor: pointer;

    p.MuiTypography-root {
      color: ${(props) => props.theme.palette.linkText.main};
      text-decoration: underline;
    }
  }

  p.MuiTypography-root {
    font-size: 1em;
    color: ${(props) => props.theme.palette.grey[600]};
  }

  .FastForwardIcon {
    margin-right: 5px;
  }
`;

export type InstructionTextProps = {
  instructions: InstructionContent;
  header?: JSX.Element;
  allowSkip: boolean;
  procedureStatus?: ProcedureStatus;
  readOnly: boolean;
  truncate: boolean;
};

interface SkipProcedureDialogInterface {
  isOpen: boolean,
  continueHandler?: Function,
}

function InstructionText({
  instructions, header, allowSkip, readOnly, procedureStatus, truncate,
}: InstructionTextProps) {
  const {
    isLoading, saveProcedure, proceedToNextProcedure, visitMode
  } = useContext(ProcedureContext);
  const { body: instructionsBody, bodyItems, questionCriteria } = instructions || {};

  const [skipProcedureDialogValues, setSkipProcedureDialogValues] = useState<SkipProcedureDialogInterface>({ isOpen: false });
  const { t } = useTranslation();
  const confirmSkipProcedure = () => {
    setSkipProcedureDialogValues({
      isOpen: true,
    });
  };

  const closeSkipProcedureConfirmation = () => {
    setSkipProcedureDialogValues((prevState) => ({ ...prevState, isOpen: false }));
  };

  if (isLoading) return null;

  let referencedVisitText;
  if (!!questionCriteria) 
  {
    // This code is a bit weird because the config endpoint returns a string
    // See docs for explanation https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
    if (isAnySandboxMode(visitMode) && questionCriteria.visitReferenceType === VisitReferenceType[VisitReferenceType.CURRENT]) {
      referencedVisitText = t('Procedure.Question.Reference.Referenced Current Visit');
    } else if (isAnySandboxMode(visitMode) && questionCriteria.visitReferenceType === VisitReferenceType[VisitReferenceType.PREVIOUS]) {
      referencedVisitText = t('Procedure.Question.Reference.Referenced Previous Visit');
    } else {
      referencedVisitText = t('Procedure.Question.Reference.Referenced Static Visit', { visitNumber: questionCriteria.visitNumber, visitName: questionCriteria.visitName });
    }
  }

  return (
    <ThemeProvider theme={crioTheme}>
      {(instructionsBody || bodyItems || questionCriteria)
        && (
          <StyledInfoText className="Instruction">
            {instructionsBody
              && (
                <>
                  {header}
                  <CollapsibleText
                    data-testid="instructionText"
                    text={instructionsBody}
                    numLines={truncate ? 3 : undefined}
                  />
                </>
              )}
            {
              bodyItems && bodyItems.length > 0
              && (
                <ul className="InstructionBodyItems">
                  {bodyItems?.map((text, index) => (
                    <li key={index}>
                      <span>
                        <FontAwesomeIcon className="InfoCircleIcon" icon={faInfoCircle} />
                        {text}
                      </span>
                    </li>
                  ))}
                </ul>
              )
            }
            {!!questionCriteria 
              && <Typography className="ReferencedFrom">
                {t('Procedure.Question.Reference.Referenced From')}
                <ul>
                  <li>{referencedVisitText}</li>
                  <li>{t('Procedure.Question.Reference.Referenced Procedure', { procedureName: questionCriteria.procedureName })}</li>
                  <li>{t('Procedure.Question.Reference.Referenced Question', { questionText: questionCriteria.questionText })}</li>
                </ul>
              </Typography>}
          </StyledInfoText>
        )}
      {
        (allowSkip && !procedureStatus)
        && (
          <div data-qa-id="SkipButton">
            <SkipProcedureButton className={`SkipButtonBody ${readOnly ? 'Disabled' : ''}`} onClick={confirmSkipProcedure}>
              <Typography>
                <FontAwesomeIcon className="FastForwardIcon" icon={faForward} />
                {t('Procedure.Dialog.SkipProcedure.Skip Procedure')}
              </Typography>
            </SkipProcedureButton>
            <SkipProcedureDialog
              {...skipProcedureDialogValues}
              closeHandler={closeSkipProcedureConfirmation}
              continueHandler={() => {
                saveProcedure({
                  skipMissingQuestionsCheck: true,
                  skipProcedure: true,
                  successCallback: proceedToNextProcedure,
                });
              }}
            />
          </div>
        )
      }
    </ThemeProvider>
  );
}

InstructionText.defaultProps = {
  header: <div />,
  procedureStatus: undefined,
};

export default InstructionText;
