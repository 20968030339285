enum NormalQuestionType {
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  FREE_ENTRY = 'FREE_ENTRY',
  CALCULATION = 'CALCULATION',
  REFERENCE = 'REFERENCE',
}

enum SyntheticQuestionType {
  NO_ENTRY = 'NO_ENTRY',
  HAS_CHANGES = 'HAS_CHANGES',
}

const isSyntheticQuestionType = (type: any): boolean => !!type && (SyntheticQuestionType.NO_ENTRY === type || SyntheticQuestionType.HAS_CHANGES === type);

type QuestionType = NormalQuestionType | SyntheticQuestionType;

export {
  type QuestionType, NormalQuestionType, SyntheticQuestionType, isSyntheticQuestionType,
};
