import { Answer, Calculation } from '.';
import { QuestionFormatType, QuestionType, SyntheticQuestionType } from '../enums';

// NOTE: We define a similar enum in
// https://github.com/clinicalresearchio/crio-common-js/blob/main/packages/esource/src/enums/VisitReferenceType.ts
// However, we don't currently reference @crio/common-js-esource in esource-spa
// and esource-spa also duplicates several other enums from that package. If we
// add common-js-esource to esource-spa we should update this, and any other
// referenced enums to those ones and remove the dupes defined in esource-spa
export enum VisitReferenceType {
  SPECIFIC = 0,
  CURRENT = 1,
  PREVIOUS = 2
}

export interface QuestionCriteriaConfig {
  visitId: string,
  visitName: string,
  visitNumber: string,
  procedureId: string,
  procedureName: string,
  questionId: string,
  questionText: string,
  visitReferenceType: keyof typeof VisitReferenceType
}

export default interface QuestionInterface {
  questionText: string,
  questionId: string | SyntheticQuestionType,
  variableName?: string, // Synthetic questions don't have variables
  cdashVariableName?: string | null,
  hint?: string | null,
  order: number,
  type: QuestionType | null,
  formatType?: QuestionFormatType | null,
  core?: boolean,
  answerOptions?: Array<Answer>,
  calculation?: Calculation,
  readOnly?: boolean,
  dataListExternalId?: string,
  restrictedToDataList?: boolean
  templateLevel?: string,
  criteria?: QuestionCriteriaConfig,
}
