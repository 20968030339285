import { RecordQuestion } from '../../../types';
import { AnswerType } from '../../../enums';
import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

/* WARNING
  We definitely do not want to get anything from the context directly at the question-level.
  Context value, when updated, triggers a blind rerender of EVERYTHING that makes use of it
  via useContext. Instead, things like handleAnswerChange and the dataPoints nested inside
  records are provided as props, therefore dodging the expensive context rerenders.
*/
function ReferenceQuestion(props: RecordQuestion) {
  const { t } = useTranslation();
  const {
    variableName, recordId, handleAnswerChange, dataPoint, questionId, referencedDataPoint
  } = props;

  console.debug('ReferenceQuestion', props);

  let { answer } = dataPoint || {};
  let { answer: referencedAnswer, answer_type: referencedAnswerType } = referencedDataPoint || {}

  if (dataPoint) {
    dataPoint.answer_type = AnswerType.TEXT;
  }

  if (referencedAnswerType === AnswerType.BULLET) {
    referencedAnswer = (referencedAnswer as string).split('\n').join(', ');
  }

  if (referencedAnswer instanceof Array) {
    referencedAnswer = (referencedAnswer as Array<string>).join(', ');
  } 
  
  let isAnswerSet = answer !== undefined && answer !== null;
  if (!isAnswerSet){
    answer = referencedAnswer;
  }

  useEffect(() => {
    if (!isAnswerSet){
      let newDataPoint = { ...dataPoint, answer }
      handleAnswerChange({
        variableName, 
        questionId, 
        newDataPoint, 
        recordId, 
        runRules: newDataPoint?.answer !== dataPoint?.answer,
      })
    }
  }, [isAnswerSet]);

  return (
    (answer === undefined) ? <span className="ReferencedValue">{t('Procedure.Question.Reference.Referenced Value')}</span>
      : (<CrioTextField
          type="text"
          name={variableName}
          value={answer}
          disabled
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newDataPoint = {
              ...dataPoint,
              answer: e.target.value,
            };

            handleAnswerChange({
              variableName,
              questionId,
              newDataPoint,
              recordId,
              runRules: newDataPoint?.answer !== dataPoint?.answer,
            });
          }}
        />
      )
  );
}
export default ReferenceQuestion;
